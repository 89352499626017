/* Add these styles to your Sidebar.css file */

.sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 100;
  }
  
  .sidebar-header {
    flex-shrink: 0;
  }
  
  .sidebar-body-container {
    flex: 1;
    overflow: hidden;
    /* position: relative; */
  }
  
  .sidebar-body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto !important;
  }
  
  /* Make sure submenu opens properly */
  .nav-item.show .nav-sub {
    display: block;
    max-height: none;
    overflow: visible;
  }
  
  /* Ensure scrollbar is visible and works properly */
  .ps {
    overflow: hidden !important;
    overflow-anchor: none;
    touch-action: auto;
  }
  
  .ps__rail-y {
    display: block;
    opacity: 0.6;
    width: 6px;
    right: 0;
    position: absolute;
  }
  
  .ps__rail-y:hover,
  .ps:focus .ps__rail-y,
  .ps--scrolling-y .ps__rail-y {
    opacity: 0.9;
  }