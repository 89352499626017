/* Dark mode background and text colors */
.date-range-picker-dark-mode {
    --rdrBackground: #1E273A;
    --rdrTextColor: #e0e0e0;
    --rdrBorderColor: #3a3a3a;
    /* --rdrDateHoverBackground: #0066cc; */
    --rdrSelectedColor: #0066cc;
    /* --rdrSelectedRangeColor: #005bb5; */
    --rdrDateColor: #ffffff;
    --rdrDayHoverColor: #fff;
    --rdrSelectedText: #ffffff;
  }
  
  .date-range-picker-dark-mode .rdrDefinedRangesWrapper,
  .date-range-picker-dark-mode .rdrCalendarWrapper,
  .date-range-picker-dark-mode .rdrDateDisplayWrapper {
    background-color: var(--rdrBackground);
    color: var(--rdrTextColor);
  }
  
  .date-range-picker-dark-mode .rdrMonthAndYearPickers select,
  .date-range-picker-dark-mode .rdrDefinedRangesItem,
  .date-range-picker-dark-mode .rdrDateDisplayItem input {
    background-color: var(--rdrBackground);
    color: var(--rdrTextColor);
    border-color: var(--rdrBorderColor);
  }
  
  .date-range-picker-dark-mode .rdrDayToday .rdrDayNumber span {
    color: var(--rdrSelectedColor);
  }
  
  .date-range-picker-dark-mode .rdrDayNumber span {
    color: var(--rdrDateColor);
  }
  
  .date-range-picker-dark-mode .rdrDay:hover .rdrDayNumber span {
    background-color: var(--rdrDateHoverBackground);
    color: var(--rdrDayHoverColor);
  }
  
  .date-range-picker-dark-mode .rdrDaySelected .rdrDayNumber span,
  .date-range-picker-dark-mode .rdrDayInRange .rdrDayNumber span {
    background-color: var(--rdrSelectedColor);
    color: var(--rdrSelectedText);
  }
  
  .date-range-picker-dark-mode .rdrDayStartPreview,
  .date-range-picker-dark-mode .rdrDayInPreview {
    background-color: var(--rdrSelectedRangeColor);
  }
  .date-range-picker-dark-mode .rdrStaticRanges button{
    background-color: var(--rdrSelectedRangeColor);
    color:var(--rdrSelectedText);
    
  }
  .date-range-picker-dark-mode .rdrStaticRanges button:hover{
    background: #ad2121 !important;
    color: var(--rdrBackground);
  }
  .date-range-picker-dark-mode .rdrDayPassive .rdrDayNumber span,
.date-range-picker-dark-mode .rdrDayDisabled span {
    color: #586160 !important;
}

  .custom-range-picker{
    width: fit-content;
    position: absolute !important;
    /* z-index: 10 !important; */
    overflow: hidden;
  }

  .dropdown-menu .dropdown-item {
    outline: none;
    color: #000;
    background: transparent !important;;
   
}

.date-range-picker{
    /* z-index: -100 !important; */
}

/* css for cron job scheduler */
.cron_builder,.cron_builder_bordering,.well{
  background:var(--rdrBackground) !important;
  /* border:var(--rdrBackground) !important */
}





/* css for statics card */
/* Container styling */
.stat-item {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
  /* background-color: #f9f9f9; */
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Icon styling */
.stat-icon {
  width: 50px;
  height: 50px;
  background-color: #e7f5ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 1.5rem;
  color: #007bff;
}

/* Text styling */
.stat-item h6 {
  margin: 0;
  font-size: 0.9rem;
  /* color: #6c757d; */
  opacity: 0.6;
}

.stat-item h4 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  /* color: #333; */
}

/* Card header styling */
.card-body h5 {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 20px;
  /* color: #333; */
}

/* Row margin and padding adjustments */
.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}
.customTable .card-body {
  background: transparent !important;
color: rgba(255, 255, 255, 0.6) !important;
}
/* .apexcharts-menu {
  background: var(--menu-bg-color) !important;
} */
body.dark-mode .apexcharts-menu {
  background: #000 !important;
  color: #fff; /* Optional: change text color for better contrast */
}

body.dark-mode .apexcharts-menu .apexcharts-menu-item:hover {
  background: #333 !important; /* Optional: hover state for dark mode */
}



/* responsive */

/* General Styles */
.date-filter-dropdown {
  /* width: 100%; */
  position: relative;
}

.date-filter-menu {
  width: 100%;
  max-width: 450px;
  padding: 10px;
  box-sizing: border-box;
  z-index: 1050; 
  /* Ensure it appears above other elements */
}

/* Fix positioning */
.date-filter-menu.show {
  position: absolute;
  top: 100%; /* Adjust to align below the dropdown */
  left: 0;
  right: 0;
}

/* Responsive adjustments */

@media (max-width:480px) {
  .rdrDateRangePickerWrapper{
    display: flex;
    flex-wrap: wrap;
    flex-grow: inherit;
  }
  .rdrDefinedRangesWrapper{
    width: 100%;
  }
}
@media (max-width: 768px) {
  .date-filter-menu {
      max-width: 100%;
      min-width: 100%;
  }

  .apply-button-wrapper {
      text-align: center;
      margin-top: 10px;
  }
  
  
}

@media (min-width: 768px) {
  .apply-button-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-right: 10px;
  }
}
.css-11gmlc3-menu{
  z-index: 100;
}